.App {
  display: flex;
  padding: 50px;
  justify-content: center;
}

body {
  background-image: url("./assets/banner.jpeg");
  background-position: center;
  background-size: cover;
}

.logo__image {
  width: 200px;
  margin-left: 100px;
  height: auto;
  margin-bottom: 6vh;
}

@media screen and (max-width: 800px) {
  .logo__image {
    width: 45vw;
    height: auto;
  }
}

.img-container {
  width: 400px;
  height: 400px;
}
.image {
  max-width: 100%;
  height: auto;
  border-radius: 30px;
}

.btn {
  margin-top: 50px;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  border: none;
  background-color: #f1f1f1;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.btn:hover {
  background-color: gray;
}
